import { faEdit, faStar, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/shared/Button';
import Searchbar from 'Components/shared/Searchbar';
import './Home.scss';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { getTeachers } from 'modules/web';
import cookies from 'modules/cookies';
import contentFilterText from 'modules/filtering';
import {
	defaultTeacherRatings,
	formatFirebaseEmail,
	toTitleCase,
} from 'modules/utils';
import Dropdown from 'Components/shared/Dropdown';
import DropdownSelector from 'Components/shared/DropdownSelector';

class Home extends Component {
	constructor() {
		super();

		this.state = {
			searchValue: '',
			teachers: {},
			loaded: false,
			sort: 'Rating',
		};
	}

	handleSearchInput(searchValue) {
		this.setState({ searchValue: searchValue.toLowerCase() });
	}

	async componentDidMount() {
		const teachers = await getTeachers();

		this.setState({
			teachers,
			loaded: true,
		});
	}

	render() {
		const loggedIn = cookies.get('loggedIn');
		const email = cookies.get('email');

		const teachers = Object.values(this.state.teachers)
			.filter((teacher) => {
				const teacherName = `${teacher.firstName} ${teacher.lastName}`;
				const searchValue = this.state.searchValue;
				// const teacherSubject = teacher.subject;

				let hasTeacherSchool = true;

				if (searchValue)
					for (let word of searchValue?.toLowerCase()?.split(' ')) {
						if (!teacher?.school?.toLowerCase()?.includes(word)) {
							hasTeacherSchool = false;

							break;
						}
					}

				return (
					teacherName
						.toLowerCase()
						.includes(searchValue.toLowerCase()) || hasTeacherSchool
					// ||
					// teacherSubject
					// 	.toLowerCase()
					// 	.includes(searchValue.toLowerCase())
				);
			})
			.sort((a, b) => {
				const sort = this.state.sort;

				if (sort === 'Rating') {
					let aTeacherRatings = a.ratings || defaultTeacherRatings;
					let bTeacherRatings = b.ratings || defaultTeacherRatings;

					let aOverallRating = 0;
					let numRatings = 0;

					let aNoRatings = false;

					Object.values(aTeacherRatings).forEach((ratings, index) => {
						let averageRating = 0;
						let ratingName = Object.keys(aTeacherRatings)[index];

						if (ratingName === 'reviews') return;

						for (const rating of Object.values(ratings)) {
							averageRating += rating;
						}

						const numPeople = Object.values(ratings).length;

						if (numPeople === 0) {
							aNoRatings = true;
						}

						averageRating /= numPeople;
						aOverallRating += averageRating;
						averageRating = Math.round(averageRating);

						numRatings++;
					});

					aOverallRating /= numRatings;
					aOverallRating = Math.round(aOverallRating);

					let bOverallRating = 0;
					numRatings = 0;

					let bNoRatings = false;

					Object.values(bTeacherRatings).forEach((ratings, index) => {
						let averageRating = 0;
						let ratingName = Object.keys(bTeacherRatings)[index];

						if (ratingName === 'reviews') return;

						for (const rating of Object.values(ratings)) {
							averageRating += rating;
						}

						const numPeople = Object.values(ratings).length;

						if (numPeople === 0) {
							bNoRatings = true;
						}

						averageRating /= numPeople;
						bOverallRating += averageRating;
						averageRating = Math.round(averageRating);

						numRatings++;
					});

					bOverallRating /= numRatings;
					bOverallRating = Math.round(bOverallRating);

					if (aNoRatings && bNoRatings) return 0;
					if (aNoRatings) return 1;
					if (bNoRatings) return -1;

					return bOverallRating - aOverallRating;
				} else if (sort === 'Name') {
					const aName = `${a.firstName} ${a.lastName}`;
					const bName = `${b.firstName} ${b.lastName}`;

					return aName.localeCompare(bName);
				} else if (sort === 'School') {
					return a.school.localeCompare(b.school);
				} else if (sort === 'Subject') {
					return a.subject.localeCompare(b.subject);
				} else if (sort === 'Number of Ratings') {
					const aTeacherRatings = a.ratings || defaultTeacherRatings;
					const bTeacherRatings = b.ratings || defaultTeacherRatings;

					const aNumRatings = Object.values(
						aTeacherRatings.difficulty
					).length;
					const bNumRatings = Object.values(
						bTeacherRatings.difficulty
					).length;

					return bNumRatings - aNumRatings;
				}
			});

		return (
			<div className='home'>
				<div className='home-header'>
					<div className='header-section'>
						<Searchbar
							onChange={this.handleSearchInput.bind(this)}
							placeholder='Search for a teacher or school'
						/>
						<Link to={loggedIn ? `/add-teacher` : '/login'}>
							<Button label='Add Teacher' cta icon={faUserPlus} />
						</Link>
					</div>
					<div className='header-section'>
						<DropdownSelector
							options={[
								'Rating',
								'Name',
								'School',
								'Subject',
								'Number of Ratings',
							]}
							label='Sort by:'
							onChange={(sort) => {
								this.setState({
									sort,
								});
							}}
						/>
					</div>
				</div>
				{!this.state.loaded ? (
					<span className='empty-teachers-label'>
						Loading teachers...
					</span>
				) : teachers.length === 0 ? (
					<span className='empty-teachers-label'>
						No teachers found!
					</span>
				) : (
					<div className='teachers-list'>
						{teachers.map((teacher, index) => {
							const teacherName = `${teacher?.firstName} ${teacher?.lastName}`;
							let teacherRatings =
								teacher.ratings || defaultTeacherRatings;

							const alreadyRated =
								email &&
								teacherRatings.difficulty?.[
									formatFirebaseEmail(email)
								];

							const reviews = teacherRatings.reviews
								? Object.values(teacherRatings.reviews)
								: [];

							let overallRating = 0;
							let numRatings = 0;

							let noRatings = false;
							let ratingSpans = Object.values(teacherRatings).map(
								(ratings, index) => {
									let averageRating = 0;
									let ratingName =
										Object.keys(teacherRatings)[index];

									if (ratingName === 'reviews') return;

									ratingName =
										ratingName.charAt(0).toUpperCase() +
										ratingName.slice(1);

									for (const rating of Object.values(
										ratings
									)) {
										averageRating += rating;
									}

									const numPeople =
										Object.values(ratings).length;

									if (numPeople === 0) {
										noRatings = true;

										return (
											<span key={index}>
												{ratingName}: No ratings
											</span>
										);
									}

									averageRating /= numPeople;
									overallRating += averageRating;
									averageRating = Math.round(averageRating);

									numRatings++;

									const ratingStars = [];

									for (let i = 0; i < averageRating; i++) {
										ratingStars.push(
											<FontAwesomeIcon
												key={i}
												className={
													averageRating === 5
														? 'star-gold'
														: 'star-solid'
												}
												icon={faStar}
											/>
										);
									}

									if (averageRating < 5) {
										for (
											let i = 0;
											i < 5 - averageRating;
											i++
										) {
											ratingStars.push(
												<FontAwesomeIcon
													key={i + 5}
													icon={faStarRegular}
												/>
											);
										}
									}

									return (
										<span key={index}>
											{ratingName}: {ratingStars}
										</span>
									);
								}
							);

							overallRating /= numRatings;
							overallRating = Math.round(overallRating);

							return (
								<Link
									to={`/teacher/${teacherName}`}
									key={index}>
									<div className='teacher'>
										<div className='teacher-header'>
											<div className='header-title'>
												<h2>{teacherName}</h2>
												<span className='teacher-subject'>
													{teacher.subject}
												</span>
											</div>
											<span className='teacher-school'>
												{teacher.school}
											</span>
										</div>
										<div className='teacher-ratings'>
											{ratingSpans}
										</div>
										<span className='number-ratings'>
											Number of ratings:{' '}
											{
												Object.values(
													teacherRatings.difficulty
												).length
											}
										</span>
										{!noRatings && (
											<div className='overall-rating-container'>
												Overall:
												<span
													className={`overall-rating ${
														overallRating >= 4
															? 'good-overall-rating'
															: overallRating >= 3
															? 'medium-overall-rating'
															: 'bad-overall-rating'
													}`}>
													{overallRating}
												</span>
											</div>
										)}
										{reviews.length > 0 && (
											<>
												<span>People have said:</span>
												<div className='teacher-reviews'>
													{reviews
														.slice(0, 3)
														.map(
															(review, index) => {
																review =
																	contentFilterText(
																		review
																	);

																return (
																	<span
																		className='teacher-review'
																		key={
																			index
																		}>
																		"
																		{review.slice(
																			0,
																			50
																		)}
																		{review.length >
																		50
																			? '...'
																			: ''}
																		"
																	</span>
																);
															}
														)}
												</div>
											</>
										)}
										<div className='teacher-controls'>
											<Link
												to={
													loggedIn
														? `/rate-teacher/${teacherName}`
														: '/login'
												}>
												<Button
													icon={
														alreadyRated
															? faEdit
															: faStar
													}
													label={
														alreadyRated
															? 'Edit Rating'
															: 'Rate'
													}
												/>
											</Link>
										</div>
									</div>
								</Link>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default Home;
