import { faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/shared/Button';
import Input from 'Components/shared/Input';
import cookies from 'modules/cookies';
import { signUp } from 'modules/web';
import { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignUp.scss';

class SignUp extends Component {
	constructor() {
		super();

		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
		};
	}

	async signUp() {
		const { email, password, confirmPassword } = this.state;

		if (!email || !password || !confirmPassword) {
			this.setState({
				error: 'Please fill out all fields.',
			});

			return;
		}

		if (password !== confirmPassword) {
			this.setState({
				error: 'Passwords do not match.',
			});

			return;
		}

		const signedUp = await signUp({ email, password });

		if (signedUp === true) {
			cookies.set('email', email);
			cookies.set('password', password);

			window.location = '/';
		} else if (signedUp === 'Banned') {
			this.setState({
				error: 'You have been banned from using this website.',
			});
		} else {
			this.setState({
				error: 'An account with that email already exists.',
			});
		}
	}

	render() {
		return (
			<div className='sign-up'>
				<h3 className='sign-up-label'>Sign Up</h3>
				<span className='login-subtext'>
					Create a new account if you do not have one
				</span>
				<div className='sign-up-inputs'>
					<Input
						label='Email'
						placeholder='Enter your email'
						tooltip='The email you want associated with your account.'
						onChange={(email) => this.setState({ email })}
					/>
					<Input
						label='Password'
						placeholder='Enter your password'
						tooltip='The password to access your account.'
						type='password'
						onChange={(password) => this.setState({ password })}
					/>
					<Input
						label='Confirm Password'
						placeholder='Enter your password again'
						tooltip='Confirm your password.'
						type='password'
						onChange={(confirmPassword) =>
							this.setState({ confirmPassword })
						}
					/>
					{this.state.error && (
						<span className='error'>{this.state.error}</span>
					)}
					<div className='sign-up-buttons'>
						<Button
							label='Sign Up'
							icon={faUserPlus}
							onClick={this.signUp.bind(this)}
							cta
						/>
						<Link to='/login'>
							<Button label='Login' icon={faUser} />
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

function SignUpWrapper() {
	const navigate = useNavigate();

	return <SignUp navigate={navigate} />;
}

export default SignUpWrapper;
