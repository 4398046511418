import { Component } from 'react';
import './DropdownSelector.scss';

class DropdownSelector extends Component {
	constructor() {
		super();

		this.state = {};
	}

	render() {
		return (
			<div className='dropdown-selector-container'>
				<label className='dropdown-selector-label'>
					{this.props.label}
				</label>
				<select
					className='dropdown-selector'
					onChange={(event) => {
						if (this.props.onChange) {
							this.props.onChange(event.target.value);
						}
					}}>
					{this.props.options.map((option, index) => {
						return (
							<option key={index} value={option}>
								{option}
							</option>
						);
					})}
				</select>
			</div>
		);
	}
}

export default DropdownSelector;
