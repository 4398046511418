import shajs from 'sha.js';

function sha256(input) {
	const hash = shajs('sha256').update(input).digest('hex');

	return hash;
}

function formatFirebaseEmail(email) {
	return email.replaceAll('.', ',');
}

function toTitleCase(str) {
	if (!str) return str;

	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

const defaultTeacherRatings = {
	difficulty: {},
	learning: {},
	personality: {},
	reviews: {},
};

export { sha256, formatFirebaseEmail, defaultTeacherRatings, toTitleCase };
